import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PrivacyPolicyPage = ({ location }) => (
  <Layout>
    <Seo location={location} title="Privacy Policy" />

    <div className="bg-white">
      <div className="max-w-5xl mx-auto py-5 px-4 sm:py-5 sm:px-6 lg:px-8">
        <div className="pt-5 pb-4 text-center">
          <p className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Privacy Policy
          </p>
        </div>

        <div className="text-lg">
          <p>
            This Privacy Policy applies to the website johngforcongress.com (the "Site") owned and operated by John
            Goncalves for Congress, a congressional campaign.
          </p>

          <p>
            John Goncalves for Congress has created this privacy policy to explain how we use information that you may
            provide while visiting this Site and to demonstrate our firm commitment to Internet privacy. John Goncalves
            for Congress may modify this policy from time to time so we encourage you to check this page when revisiting this
            Site. By using this Site, you agree to the terms of this Privacy Policy.
          </p>
          <h3><strong>How we use your information</strong></h3>
          <p>
            When you register, contribute, sign up to volunteer, or take any other action on our Site, we may ask you to give us
            contact information, including your name, address, telephone number, mobile telephone number and/or e-mail address. We
            may obtain information about you from outside sources and add it to or combine it with the information we collect
            through this Site.
          </p>
          <p>
            We use this information to operate this Site, send you news and information about John Goncalves for
            Congress's activities, solicit your participation in John Goncalves for Congress's activities, and related
            uses. We use your e-mail address to send such information by e-mail, and may use your telephone number to call you or
            send you a text message or phone call for these purposes.
          </p>
          <p>
            Additionally, we may share your information as follows, or as otherwise described in this Privacy Policy:
          </p>
          <p>
            To companies that assist us in maintaining this Site or with the John Goncalves for Congress activities, but
            only for purposes of providing services to John Goncalves for Congress and with the strict requirement that
            any such company will never itself have the right to contact you for any reason;
          </p>
          <p>
            To comply with applicable law or legal requirements (for example, a lawful subpoena), to protect our rights or
            property, or to protect our supporters from fraudulent, abusive, or unlawful conduct, or if we reasonably believe that
            an emergency involving immediate danger of death or serious physical injury to any person requires disclosure of
            communications or justifies disclosure of records;
          </p>
          <p>
            With candidates, organizations, committees, or groups that we believe share the same political or social viewpoints or
            objectives as John Goncalves for Congress; or
          </p>
          <p>
            To other groups with your consent.
          </p>
          <p>
            Submitting your personal information (including e-mail address) on this page will sign you up to receive e-mails from
            John Goncalves for Congress. We may also use your first name to indicate the latest actions taken on this
            site.
          </p>

          <h3><strong>Children</strong></h3>
          <p>
            John Goncalves for Congress complies with the Children's Online Privacy Protection Act. John Goncalves for Congress does not solicit and does not accept personally identifying information from any person
            under 13 years of age.
          </p>

          <p>
            Visitors to this Site who are under 13 years of age should not use this Site except under the supervision and with the
            assistance of their parent or legal guardian, and should never submit any personally identifying information to the
            Site. For example, visitors under 13 years of age should not register, purchase or use services or take any actions on
            or through this Site.
          </p>
          <h3><strong>
            Credit card information; protection of personal information
          </strong></h3>
          <p>
            When you purchase services through this Site, we collect credit card information from you. That information is used
            solely for processing your contribution; is not maintained by John Goncalves for Congress; and is never
            disclosed to anyone, for any other purpose other than for processing your contribution, under any circumstances.
          </p>
          <p>
            John Goncalves for Congress uses industry standard security measures to protect against the loss, misuse or
            alteration of all of the personally identifiable information that you provide to us. Our server is located in a
            locked, secure environment. All supplied credit card information is transmitted via Secure Socket Layer (SSL)
            technology and then encrypted into our payment processing provider's database.
          </p>
          <p>
            Permission to access your personally identifiable information is granted only to you and John Goncalves for
            Congress employees or contractors who need to know that information to provide services to you and who are required to
            keep the information confidential. Although we make good faith efforts to store information collected by this Site in
            a secure operating environment, we cannot guarantee complete security.
          </p>
          <h3><strong>
            Contributions to John Goncalves for Congress - public information
          </strong></h3>
          <p>
            John Goncalves for Congress is required to file regular reports with the Federal Election Commission that
            publicly disclose the name, address, occupation and employer of persons who contribute over $200 during an election
            cycle, along with the amount and date of the contribution. State and local election laws may also require us to
            disclose this information at a lower itemization threshold, depending on the committee's activity.
          </p>
          <h3><strong>
            Cookies and data tracking
          </strong></h3>
          <p>
            A cookie is a piece of data stored on the user's hard drive containing information about the user. Some parts of this
            Site may be password-protected. We may use a cookie (cookies reside on your computer and are under the control of your
            browser) to help us remember and process items that you purchase through this Site; and/or to compile aggregate data
            about visitors to the Site and their interaction with the Site for the purposes of improving the operation of the Site
            and/or offering better Site experiences and tools in the future.
          </p>
          <p>
            We may also use cookies to enable you to return to password-protected areas of the Site without having to re-enter
            your password. If you wish to disable these cookies, the help portion of the tool bar on most browsers can assist. If
            you set your browser to disable cookies, however, you may not be able to access certain parts of this Site.
          </p>
          <p>
            We may also use third-party services such as Google Analytics. This helps us understand traffic patterns and know if
            there are problems with our Site. We may also use embedded images in emails to track open rates for our mailings, so
            that we can tell which mailings appeal most to John Goncalves for Congress supporters.
          </p>
          <p>
            Please note that third party vendors, including Google, may show John Goncalves for Congress-sponsored ads on
            the Internet. Those third party vendors (including Google) use cookies to serve ads based on a user's prior visits to
            our website. Users may opt out of Google's use of cookies by visiting http://www.google.com/privacy/ads/.
          </p>

          <h3><strong>
            Advertising
          </strong></h3>
          <p>
            We may place online advertising with third-party vendors, including Google, which will be shown on other Sites on the
            internet. In some cases, those third-party vendors may decide which ads to show you based on your prior visits to the
            Site. At no time will you be personally identified to those third-party vendors, nor will any of the information you
            share with us be shared with those third-party vendors. If you prefer to opt out of the use of these third-party
            cookies on the Site, you can do so by visiting the Network Advertising Initiative opt out page .
          </p>
          <p>
            John Goncalves for Congress does not use cookies to track what specific pages an individual site visitor
            views. Please note, however, that third party vendors, including Google, may show John Goncalves for
            Congress-sponsored ads on the Internet. Those third party vendors (including Google) use cookies to serve ads based on
            a user's prior visits to our website. Users may opt out of Google's use of cookies by visiting
            http://www.google.com/privacy/ads/.
          </p>
          <h3><strong>
            Links to other sites
          </strong></h3>
          <p>
            The privacy policies and practices contained in this Privacy Policy do not apply to ANY external links. This Privacy
            Policy only applies to our Site or any future Sites that we may develop. It does not cover Sites that are linked to by
            this Site or Sites for which we are not responsible ("linked-Sites"). These linked-Sites will have their own policies
            and practices that may be different from ours. We encourage you to familiarize yourself with the policies and
            practices of the linked-Sites, especially if you provide personal information to them.
          </p>
          <h3><strong>
            California online privacy protection act compliance
          </strong></h3>
          <p>
            John Goncalves for Congress complies with the California Online Privacy Protection Act. As part of that Act,
            all registered users of our Site may make any changes to their information at any time by logging into their Control
            Panel and going to the "Edit Profile" page. Changes to billing and/or credit card information must be made through
            John Goncalves for Congress staff and may be initiated by contacting <a
              href="mailto:info@johngforcongress.com">info@johngforcongress.com</a>.
          </p>
          <h3><strong>
            Amendments and consent to this privacy policy
          </strong></h3>
          <p>
            We retain the right to amend or otherwise update this Privacy Policy at any time. By using our Site, you consent to
            the collection and use of the information as we have described. If we change our policies and practices, we will post
            the changes in our Privacy Policy so that you are always aware of them. With this knowledge, you can make an informed
            decision about whether you wish to provide personal information to us.
          </p>
          <h3><strong>How to contact us</strong></h3>
          <p>
            If you have any questions about this Privacy Policy, you may contact us by sending an e-mail to <a
              href="mailto:info@johngforcongress.com">info@johngforcongress.com</a>.
          </p>
		    </div>
      </div>
    </div>
  </Layout>
)

export default PrivacyPolicyPage
